
@import "../../../../sass/imports";

.info-modal {
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    width: 563px;
    max-width: 95%;
    min-height: 350px;

    z-index: 11;
}

.editor-modal {
    top: 5%;
    left: 50%;
    @include transform(translateX(-50%));
    bottom: 5%;
    z-index: 11;
    width: 675px;

    @include max-screen(675px) {
        top: 0; bottom: 0;
        left: 0; right: 0;
        width: 100vw;
        @include transform(translateX(0));
    }

    @include max-screen-height(750px) {
        min-height: auto;
    }
}

.editor-modal-overlay {
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.dialog-form-modal {
    top: 5%;
    left: 50%;
    bottom: 5%;
    width: 563px;

    @include max-screen(563px) {
        width: 95%;
    }

    @include min-screen-height(700px) {
        bottom: auto;
        height: calc(700px - 5%);
    }

    @include transform(translateX(-50%));

    z-index: 11;
}

.fullscreen-modal {
    background-color: white;
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
}

.wide-modal {
    background-color: white;
    position: absolute;
    top: 5%;
    bottom: 5%;
    left: 5%;
    right: 5%;
}

.subscription-modal {
    top: 5%;
    left: 50%;
    // bottom: 5%;
    @include transform(translateX(-50%));
    min-width: 700px;
    height: 600px;
    z-index: 11;

    @include max-screen(700px) {
        min-width: auto;
        width: 95%;
    }

    @include max-screen-height(620px) {
        height: auto;
        bottom: 5%;
    }
}

.modal-container, .fullscreen-modal-container, .wide-modal-container {
    background-color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .modal-scroll-container {
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        padding-bottom: 0;
        padding-top: 1px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.modal-utils-scroll-container {
    overflow-y: scroll;
    width: 100%;

    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}

.modal-util-tools, .modal-container, .fullscreen-modal-container, .wide-modal-container {

    > header {
        button {
            position: absolute;
            top: 0;
            right: 0;
            border: none;
            background: rgba(255, 255, 255, 0.5);
            @include border-radius(100%);
            font-size: 14px;
            padding: 8px 10px;
            margin: 5px 10px;
            z-index: 10;

            @supports (top: constant(safe-area-inset-top)) {
                top: constant(safe-area-inset-top); /* iOS 11.0 */
            }
            @supports (top: env(safe-area-inset-top)) {
                top: env(safe-area-inset-top); /* iOS 11.2 */
            }
        }
    }

    > footer, .modal-action-footer {
        width: 100%;
        text-align: center;
        padding: 15px;

        .cancel-btn, .ok-btn {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.1));
            background-color: white;
            border: solid 0.5px $inactive-text-gray;
            font-size: 14px;

            padding: 10px;
            min-width: 100px;
            width: calc(50% - 20px);
            max-width: 200px;
            margin: 10px;
            text-transform: uppercase;
            color: $icon-inactive;

            border: solid 0.5px $inactive-text-gray;
            @include border-radius(40px);

            @include transition(all 0.333s ease-in-out);

            &:hover {
                background-color: $inactive-text-gray;
                color: white;
            }

            &:focus {
                @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.3));
            }

            &:disabled {
                opacity: 0.25;
            }

            .icon-lock {
                display: inline-block;
                margin: 0 3px;
                font-size: 12px;
            }
        }

        .ok-btn {
            border: solid 0.5px $raspberry;
            background-color: $raspberry;
            color: white;

            &:hover {
                background-color: darken($raspberry, 20%);
                border: solid 0.5px darken($raspberry, 20%);
                color: white;
            }
        }
    }
}

.editor-modal-container {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; width: 100%;
    background-color: white;

    .editor-scrollable {
        position: absolute;
        top: 70px;
        bottom: 0;

        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
    }

    .modal-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 70px;
        background-color: white;

        .close-btn {
            position: absolute;
            top: 0; right: 0;
            border: none;
            background: none;
            font-size: 14px;
            padding: 18px;
            z-index: 10;
        }

        h2 {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            color: $text-gray;
            margin-top: 15px;

            @include min-screen(675px) {
                margin-top: 41px;
            }

            em {
                font-weight: 300;
                color: $inactive-text-gray;
            }
        }
    }

    .modal-footer {
        width: 100%;
        // height: 166px;
        text-align: center;
        border-top: solid 0.5px #ddddea;
        padding: 15px;

        .prev, .prev-with-skip {
            @include outline-button($bluey-gray);
        }

        .skip {
            @include outline-button($bluey-gray);
        }

        .next {
            @include flat-button($mint-green3);
        }

        .page-dots {
            padding-top: 15px;
            i {
                font-size: 6px;
                color: rgba(154, 158, 179, 0.2);
                margin: 5px;

                &[data-active="true"] {
                    color: $raspberry;
                }
            }
        }
    }

    .warning-msg {
        color: $text-gray;
        font-weight: bold;
        padding: 10px 40px;
        font-size: 13px;

        &:before {
            @include icon-font;
            content: $icon-warning;
            float: left;
            font-size: 25px;
            padding: 0px 10px;
            color: $orange3;
        }
    }

    .error-msg {
        opacity: 0;
        color: red;
        font-weight: bold;
        padding: 0 40px;
        font-size: 12px;

        &[data-active="true"] {
            opacity: 1;
        }
    }
}

