
@import "../../../../../sass/imports";

.patient-editor-working {
    position: absolute;
    top: 0;
    // @include transform(translateY(-50%));
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    z-index: 10;
    width: 100%;
    padding: 40px;

    h2 {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: $text-gray;
        margin: 50px;
    }

    .icon-spinner {
        display: block;
        text-align: center;
        font-size: 48px;
    }

    p {
        max-width: 300px;
        text-align: center;
        margin: 50px auto 0 auto;
    }
}
