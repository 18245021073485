
@import "../../../../../sass/imports";

.energy-calculator {
    .fields {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @include max-screen(660px) {
            flex-direction: column;

            > div {
                width: 100%;
            }
        }
    }

    .patient-geometry {
        text-align: center;
        margin-bottom: 20px;

        li {
            display: inline-block;
            padding: 0 10px;
            font-size: 14px;
            color: $text-gray;
            font-weight: bold;

            em {
                color: $raspberry;
            }
        }
    }

    .energy-method, .activity-level, .kcal-kg-container, .goal-weight, .weekly-goal {
        width: 279px;
        margin: 10px;
        vertical-align: top;
    }

    .kcal-kg-container {
        display: inline-block;

        input {
            width: 100%;
        }
    }

    .target-energy-kcal {
        width: 279px;
    }

    .computed-energy-kcal {
        font-size: 16px;
    }

    .computed-energy-needs, .target-energy-needs, .portion-size {
        font-size: 12px;
        color: $text-gray;

        > div {
            display: inline-block;
            vertical-align: middle;
            margin: 10px;
            width: 160px;

            @include min-screen(660px) {
                width: 279px;

                &:first-child {
                    text-align: right;
                }

                &:last-child {
                    text-align: left;
                }
            }
        }
    }

    .target-energy-needs {
        input {
            width: 250px !important;

            @include max-screen(660px) {
                width: 100% !important;
            }
        }
    }

    .portion-size .select-container {
        width: 250px;

        @include max-screen(660px) {
            width: 100%;
        }

        .select-dropdown .dropdown-content .sub-label-short  {
            label {
                padding: 5px 5px 5px 0;
            }
        }
    }
}
