
@import "../../../../../sass/imports";

.about-your-patient {
    input[type="text"] {
        width: 100%;
    }

    .with-units input {
        width: calc(100% - 35px);
    }

    .first-name, .last-name, .email1, .email2, .patient-number, .phone-number, .current-gender-identity {
        width: calc(50% - 10px);

        @include min-screen(675px) {
            width: calc(50% - 20px);
        }
    }

    .phone-number {
        min-width: 200px;
    }

    .practice-type, .inhibit-jabber, .expiration, .days-after {
        width: calc(33% - 10px);
        min-width: 150px;

        @include min-screen(675px) {
            width: calc(33% - 20px);
        }
    }

    .inhibit-jabber > p {
        font-size: 14px;
        color: $text-gray;
        font-weight: bold;
    }

    .react-tel-input {
        width: 100%;

        input {
            color: $raspberry;
        }
    }

    .date-selector-container {
        text-align: initial;
        margin: 10px;
        .month, .day {
            margin: 0 10px 0 0;
        }

        .year {
            margin: 0;
        }
    }

    .date-select-container {
       .prev-date-btn-wrapper, .next-date-btn-wrapper {
            display: none;
        }
    }

    .genders-container {
        border: 1px solid transparent;
        width: 75%;
        margin: 0px 10px 0 0;

        .toggle-button {
            margin: 10px 10px 0 0;
        }

        &[data-error="true"] {
            border: solid 0.5px $error-border !important;
            @include box-shadow(0, 2px, 6px, 0, rgba(255, 0, 0, 0.17));
        }
    }

    .weight-height-container {
        .units-mode {
            vertical-align: middle;
            margin-top: 25px;
            margin-right: 10px;
        }

        .height, .weight {
            vertical-align: top;
            /*width: 280px;*/
        }

        .height {
        }

        .height-feet, .height-inches {
            width: 90px !important;
        }

        .height-inches {
            padding-left: 0;

            .value {
                padding-left: 17px;
            }

            .placeholder {
                padding-left: 10px !important;
            }
        }

        .height-feet {
            margin-right: 5px;
        }

        .weight-lbs, .weight-kg, .height-cm {
            width: 130px !important;
        }

        .height > label {
            @include transition(padding-right .2s ease-in-out);
        }

        &[data-units="metric"] .height > label {
            padding-right: 25px;
        }
    }

    .bmi-container {
        color: $raspberry;
        font-size: 12px;
        margin: 10px;
        text-align: center;

        em {
            color: $text-gray;
            font-weight: 900;
        }
    }

    .date-selector-popover {
        .dropdown-btn {
            border: .5px solid #a8acbe;
            font-size: 16px;
            color: #700044;
            background-color: white;
            padding: 4px 20px;

            text-align: center;
            width: 200px;
            margin: auto;
            display: block;

            i {
                color: $raspberry;
                font-size: 22px;
                margin: 0 20px 0 0;
                vertical-align: middle;
            }
        }

        .popup-dropdown {
            z-index: 99;

            @include max-screen(515px) {
                left: 0;
                transform: none;
                
                .triangle, .triangle2 {
                    left: 10%;
                }
            }

        }


        .dropdown-dropdown {
            @include transform(translate(-25%, 0px) !important);
            bottom: calc(100% + 10px);
            .triangle, .triangle2 {
                @include transform(rotateZ(180deg));
                top: initial;
                bottom: -8px;
            }
        }

        .dropdown-content {
            padding: 0 !important;
            border-radius: 10px;
            min-width: 280px;

            .close-btn {
                display: none;
            }
        }

    }

    &.patient-form {

        .el-pro-checkbox {
            margin: 1.8em 0px 0.8em 30px;
            font-size: 10px;
            text-transform: uppercase;
            color: $text-gray;
            letter-spacing: 1px;
            font-weight: 700;

            p {
                color: $inactive-text-gray;
                text-transform: none;
                font-size: 13px;
                letter-spacing: normal;
                font-weight: 550;
            }        
        }

    }
}


.el-nutrition-info-notifications {
    position: relative;

    .nutrition-info {
        display: flex;
    }
}

.popup-wrapper {
    position: relative;
    top: -2px;
    .icon-info::before {
        font-size: 14px;
    }
}

.info-icon-paragraph{
    position: relative;
    top: -2px;
}