
@import "../../../../../sass/imports";

.brands-db-location-search {
    min-height: 600px;
    max-width: 1210px;
    @include transition(max-width 0.25s ease-in-out);
    margin: auto;
    overflow: hidden;

    &[data-product-results="true"] {
        max-width: 971px;
    }

    @include min-screen(47em) {
        margin: -20px 0 -20px -10px;
        width: calc(100vw - 60px);
    }

    @include max-screen(47em) {
        &:before {
            content: ' ';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 50%;
            width: 145vw;
            height: 205px;

            @include transform(translate(-50%,calc(-100% + 193px)));
            @include border-radius(50%);

            @include background-image(linear-gradient(0deg, #5b7287, #324253 50%));
            @include box-shadow(0, 2px, 8px, 0, rgba(0, 0, 0, 0.5));
        }
    }

    .loading, .no-results-found {
        text-align: center;

        h2 {
            font-size: 18px;
            font-weight: bold;
            color: $text-navy;
            padding: 32px 0 23px 0;
        }

        p {

            font-size: 16px;
            color: $text-navy;
            &:last-of-type {
                margin-top: 10px;
            }
        }

        i {
            display: inline-block;
            color: $raspberry;
            font-size: 36px;
        }

        > footer {
            padding: 26px 0;
        }
    }

    .keyword-search {
        width: calc(100% - 10px);
        margin: 5px;
    }

    .location-result-item {
        border-bottom: .5px solid #d8d8d8;
        padding: 15px 0;
        clear: both;
    }

    .establishment-info {
        padding: 10px;
    }

    .location-result-item, .establishment-info {
        margin: 0;
        cursor: pointer;

        h3 {
            font-size: 15px;
            color: $text-navy;
            font-weight: bold;
        }

        p {
            color: #7c8a99;
            font-size: 13px;
        }

        .matching {
            float: right;
            display: inline-block;
            max-width: 75px;
            text-align: right;
            margin: 0 0 0 10px;

            color: $text-navy;
            font-size: 15px;
            font-weight: bold;
        }

        .yelp-price {
            font-size: 13px;
            em {
                color: $raspberry;
                font-weight: 800;
            }
        }
    }



    .location-inner-slider {
        white-space: nowrap;

        * {
            white-space: initial;
        }

        .slider-panel {
            display: inline-block;
            vertical-align: top;
            width: 100%;
        }
    }

    .location-results {
        position: relative;

    }

    .product-results {
        padding: 0 0 60px 0;
        background-color: $super-light-gray;

        > header {
            position: relative;
            z-index: 3;
        }

        > footer {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: white;
            text-align: center;
            border-radius: 0px 0px 10px 10px;
        }

        .nutrition-analysis h3 {
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            margin: 0 0 0.5em 0;
            color: $text-gray;
        }
    }

    .open-filters-btn {
        position: absolute;
        top: 50%;
        right: 0;
        @include transform(translate(0, -50%));
        border: none;
        background: none;
        font-size: 20px;
        padding: 15px;
        color: $text-gray;
    }

    .return-to-locations-btn {
        color: white;
        font-size: 15px;
        border: none;
        background: none;
        padding: 15px 20px;

        @include min-screen(47em) {
            color: $raspberry;
            padding: 15px 30px 10px 30px;
        }
    }

    .create-meal-btn, .ok-btn, .cancel-btn {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 1.2px;
        color: white;
        background-color: $raspberry;
        @include border-radius(40px);
        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(163, 0, 99, 0.2);
        padding: 9px 22px;
        border: none;
    }

    .report-issue-btn, .ok-btn, .cancel-btn {
        margin: 15px;
    }

    .cancel-btn {
        background-color: $bluey-gray;
        @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.1));
    }

    .ok-btn:disabled {
        opacity: 0.5;
    }

    .report-issue-btn {
        border: none;
        background: none;
        color: $raspberry;
        font-size: 14px;
        padding: 15px;
        margin: 0;

        @include min-screen(47em) {
            margin: 0 40% 0 0;
        }
    }

    .location-info {
        margin: 21px auto 0 auto;

        @include min-screen(47em) {
            border-bottom: 1px solid $inactive-text-gray;
            margin: 5px auto 0;
            padding: 0 0 10px 0;
        }
    }

    .product-results-list {
        padding-bottom: 50px;
        margin: 0 auto;
        max-width: 760px;
        background: #ECEFF1;

        @include min-screen(47em) {
            overflow: auto;
        }

        > header {
            max-width: 480px;
            margin: 0 20px;
            position: relative;

            @include min-screen(520px) {
                margin: auto;
            }
        }

        > footer {
            text-align: center;
            padding: 20px 0;
        }

        .browser-pager {
            @include outline-button($raspberry);
            @include border-radius(0);
            background-color: transparent;
            font-size: 12px;
            text-transform: uppercase;
            padding: 8px 10px;
            border-width: 0px;
            text-decoration: underline;
            text-align: center;
            margin: 5px;
        }
    }

    .product-result-item {
        clear: both;
        color: $text-navy;
        font-size: 14px;
        margin: 0;
        padding: 15px 40px 15px 20px;
        border-bottom: 1px solid #d8d8d8;
        cursor: pointer;
        @include transition(all 0.1s ease-in-out);

        h4 {
            font-size: 12px;
            font-weight: bold;
            color: $text-navy;
        }

        p {
            > span {
                &:after {
                    @include icon-font;
                    content: $icon-bullet;
                    font-size: 6px;
                    display: inline-block;
                    margin: 0 5px;
                    vertical-align: top;
                    padding: 5px 0 0 0;
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        &[data-choice-type="smart"] {
            background-color: #c4eadc;

            .choice-type {
                color: #18a371;
            }
        }

        &[data-choice-type="ok"] {
            background-color: #fbf3e3;

            .choice-type {
                color: $bluey-gray;
            }
        }
    }

    .select-product-btn {
        float: left;
        width: 30px;
        height: 30px;
        margin: 0 12px 10px 0;
        background: none;
        border: 1px solid $bluey-gray;
        @include border-radius(30px);

        &[data-selected="true"] {
            background-image: radial-gradient(circle at 50% 50%, #57f1b9, #14bd80);
        }
    }

    .product-stats {
        float: right;
        white-space: nowrap;
        text-align: right;

        .choice-type {
            margin: 0 0 0 10px;
            white-space: normal;
            width: 42px;
            font-size: 13px;
            display: inline-block;
            text-align: left;
            vertical-align: top;
            line-height: 1.1;
            font-weight: bold;
        }

        .kcal {
            display: inline-block;
            width: 40px;
            text-align: right;
            font-size: 15px;
            font-weight: bold;
        }
    }

    .product-result-container{
        display: inline-block;
        max-width: calc(100% - 175px);
    }

    .outer-map-container, .outer-results-container {
        @include min-screen(47em) {
            display: inline-block;
            vertical-align: top;
        }
    }

    .outer-map-container {
        @include min-screen(47em) {
            width: 60%;
            @include box-shadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.25));
        }
    }

    .outer-results-container {
        @include min-screen(47em) {
            width: 40%;
            position: relative;

            &:before {
                content: ' ';
                display: block;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 10px;
                background-color: red;
                width: 100%;
                height: 150px;
                background: linear-gradient(to bottom, rgba(255,255,255,1) 69%,rgba(255,255,255,0) 100%);
            }
        }
    }

    .inner-results-container {
        padding: 0 20px 100px 20px;

        @include min-screen(47em) {
            padding: 150px 50px 50px 50px;
            max-height: 590px;
            overflow-y: auto;
        }

        .report-issue-btn {
            float: right;
            margin: 0;
            padding: 0 0 0 20px;

            @include min-screen(47em) {
                position: absolute;
                z-index: 9;
                top: 0;
                right: 0;
                padding: 0 20px;
            }
        }
    }

    .nutrition-analysis {
        padding: 15px 20px 15px 20px;
        text-align: center;
        max-width: 760px;
        overflow: auto;
        white-space: nowrap;

        margin: auto 20px;

        @include min-screen(800px) {
            margin: auto;
        }
    }

    .food-unit-selector {
        display: inline-block;
        width: auto;
        background-color: white;
        @include box-shadow(0, 1px, 4px, 0, rgba(0, 0, 0, 0.36));
        @include border-radius(3px);

        .placeholder {
            color: $bluey-gray;
            font-style: italic;
        }

        .select-dropdown {
            right: 0; left: auto;
            @include transform(translate(0, 0));
        }
    }

    .how-much-eat {
        margin: 5px 0 0 0;
        label {
            display: block;
            font-size: 12px;
            font-weight: bold;
        }

        .select-container {
            display: block;
            text-align: left;
            padding: 0 25px 0 0;

            .value {
                font-size: 12px;
                color: $text-navy;
            }

            .select-dropdown {
                width: auto;
                min-width: 160px;
            }
        }
    }
}

.map-keywords-filter {
    z-index: 2;
    max-width: 335px;
    margin: 0 auto;
    position: relative;

    @include min-screen(47em) {
        position: absolute !important;
        left: calc(60% + 50px);
        top: 26px;
        width: 29vw;
    }
}

.product-keyword-search {
    position: relative;
    margin: 10px 0;
    width: calc(100% - 50px);
}

.map-keywords-filter, .product-keyword-search, .full-browser-keyword-search, .recipe-keywords-search {
    position: relative;

    input {
        width: calc(100% - 40px);
        border-radius: 40px;

        border: none;
        @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.2), true);
        font-size: 13px;
        padding: 11px 0px 11px 40px;
        color: $text-gray;
        @include transition(box-shadow 0.25s ease-in-out);
        -webkit-appearance: none;

        &:focus {
            @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.3));
        }

        @include placeholder {
            color: darken($text-gray, 10%);
            text-transform: uppercase;
            font-size: 12px;
        }
    }

    &:before {
        position: absolute;
        top: 50%;
        left: 8px;
        @include transform(translateY(-50%));
        @include icon-font;
        content: $icon-search3;
        color: $raspberry;
        font-size: 18px;
        padding: 5px 10px 5px 5px;
        pointer-events: none;
    }

    .clear-search-btn {
        position: absolute;
        top: 50%;
        right: 0;
        @include transform(translateY(-50%));
        font-size: 12px;
        padding: 12px;
        color: $text-gray;
        opacity: 0.0;
        @include transition(opacity .25s ease-in-out);
        border: none;
        background: none;
    }

    &[data-has-terms="true"] {
        .clear-search-btn {
            opacity: 1;
        }
    }

}

.full-browser-keyword-search:before {
    color: $raspberry;
}

.add-swap-favorites {
    .full-browser-keyword-search:before {
        display: block;
    }
}

.nutrition-disclaimer {
    h2 {
        text-align: center;
        font-size: 20px;
        margin: 15px 0;
        color: $text-navy;
    }

    p {
        color: $text-navy;
        font-size: 16px;
    }
}
