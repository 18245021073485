
@import "../../../../../sass/imports";

.edit-nutrition-prescription {
    @include max-screen(47em) {
        padding: 0 10px 10px 10px !important;
    }

    .envelope-editors {
        h1 {
            display: inline-block;
            width: 215px;
            font-size: 14px;
            color: $raspberry;
            font-weight: bold;
            margin-left: 10px;
        }

        &[data-conflicts="true"] .envelope-mode .value:after,
        &[data-breakfast-conflicts="true"] [data-tab="Breakfast"]:before,
        &[data-lunch-conflicts="true"] [data-tab="Lunch"]:before,
        &[data-dinner-conflicts="true"] [data-tab="Dinner"]:before,
        &[data-snack-conflicts="true"] [data-tab="Snack"]:before {
            @include icon-font;
            content: $icon-warning;
            color: $orange;
            display: inline-block;
            padding: 0 5px;
            font-size: 18px;
            vertical-align: middle;
        }

        &[data-all-day-conflicts="true"] .envelope-mode li .all-day:after,
        &[data-meals-conflicts="true"] .envelope-mode li .meals:after {
            @include icon-font;
            content: $icon-warning;
            color: $orange;
            display: inline-block;
            padding: 1px 2px 2px 2px;
            margin: 3px;
            font-size: 18px;
            vertical-align: middle;
            float: right;
        }
        &[data-all-day-conflicts="true"] .envelope-mode li[data-selected="true"] .all-day:after,
        &[data-meals-conflicts="true"] .envelope-mode li[data-selected="true"] .meals:after {
            @include border-radius(100%);
            background-color: white;
        }
    }

    .envelope-mode {
        width: 200px !important;
        border: none !important;
        padding: 4px;

        .value {
            color: $raspberry;
        }
    }

    .expires-in {
        display: block;
        text-align: left;

        .select-container {
            width: 280px;
        }
    }

    .explainer {
        text-align: center;
        font-size: 12px;
        color: $text-gray;
    }

    .generic-tabs {
        .tab-contents {
            @include max-screen(47em) {
                margin: 0;
            }
            footer {
                text-align: center;

                p.next-update-needed {
                    color: $text-gray;
                    font-size: 14px;
                    text-transform: uppercase;
                    padding: 10px;

                    em {
                        font-weight: bold;
                    }
                }
            }

        }

        .tab-btn {
            @include max-screen(47em) {
                padding: 1.75em 6px;
            }
        }
    }



}
