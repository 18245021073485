
@import "../../../../sass/imports";

.dashboard-empty-wizard {
    max-width: 1000px;
    margin: auto;
    text-align: center;
    padding: 10px 0;

    @include min-screen(820px) {
        padding: 20px 0;
    }

    .graffiti {
        max-width: 300px;
        width: 100%;
        text-align: center;
        margin: 0 auto 38px auto;
        padding: 0 20px;

        @include min-screen(820px) {
            margin: 20px 60px 0 0;
            text-align: left;
            display: inline-block;
        }

        h1 {
            font-size: 20px;
            font-weight: bold;
            color: $text-gray;
            margin: 0 0 6px 0;
            @include min-screen(820px) {
                text-align: left;
            }
        }

        h2 {
            font-size: 16px;
            font-weight: normal;
            color: $text-gray;
            margin: 0;
        }

        .clipboard-apple {
            max-width: 34px;
            margin: 40px 0 20px 0;

            @include min-screen(820px) {
                max-width: 74px;
                margin: 45px 0 31px 0;
            }
        }

        .pointing-arrow {
            float: right;
            max-width: 121px;
            margin-bottom: 40px;

            display: none;
            @include min-screen(820px) {
                display: inline-block;
            }
        }

        p {
            clear: both;
            font-size: 14px;
            line-height: 1.29;
            letter-spacing: 0.65px;
            color: $another-josh-gray;

            display: none;
            @include min-screen(820px) {
                display: inline-block;
            }
        }

        a {
            color: $raspberry;
        }
    }

    .training {
        margin: 2em 0;
    }

    .total-recipes {
        position: absolute;
        top: 22px;
        right: 34px;
        text-align: center;
        font-size: 14px;
        line-height: 1.14;
        font-weight: bold;
        color: $text-gray;

        @include max-screen(47em) {
            left: 219px;
            top: 18px;
        }

        em {
            display: block;
            color: $mint-green3;
            font-size: 18px;
        }
    }

    .try-it-module {
        position: relative;
        display: inline-block;
        max-width: 380px;
        width: calc(100% - 40px);
        // min-width: 300px;
        vertical-align: top;
        margin: 20px 20px 20px 20px;
        padding: 25px;
        text-align: left;

        background-color: white;
        @include box-shadow(0, 8px, 10px, 0, rgba(0, 0, 0, 0.1));

        > header {
            margin-top: 20px;
            margin-bottom: 20px;
            display: inline-block;
            position: absolute;
            left: -20px;
            top: 0px;
            background-color: $another-josh-gray;
            color: white;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 1px;
            padding: 14px 15px;
            text-transform: uppercase;
        }

        h2 {
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
            color: $text-gray;
            text-align: center;
            width: 100%;
            margin: 70px 0 25px 0;
        }
    }

    footer {
        .get-meal-plan-btn {
            @include flat-button($raspberry);
            @include border-radius(40px);
            @include box-shadow(0, 10px, 10px, 0, rgba(0, 0, 0, 0.1));
            @include transition(all 0.25s ease-in-out);
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            padding: 14px 25px;
            margin: 5px;
            vertical-align: middle;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            letter-spacing: 1px;   
            margin: auto;
            display: block;

            i {
                display: inline-block;
                padding: 0 8px;
                vertical-align: middle;
            }

        }    
    }
}
