
@import "../../../../../../sass/imports";

.add-meal-rx {

    text-align: center;
    padding: 20px;

    > button {
        @include outline-button($text-gray);
        @include border-radius(0);
        border-width: 1px;
        text-align: center;
        font-size: 10px;
        padding: 12px 22px;
    }
}
