
@import "../../../../../sass/imports";

.edit-family {
    padding-top: 10px;

    .subtext, .quick-add-family > p {
        text-align: center;
        padding: 20px 0;
        color: $text-gray;
        font-size: 14px;

        em {
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .spinners {
        display: flex;
        justify-content: center;
    }

    .spinner {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 130px;
        min-width: 100px;
        position: relative;
        border: 1px solid $raspberry;
        color: $raspberry;
        text-align: center;
        padding: 10px 0;
        margin: 10px;

        .increment, .decrement {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 28px;
            font-weight: 800;
            border: none;
            background: none;
            padding-bottom: 20px;
        }

        .decrement {
            left: 0;
        }

        .increment {
            right: 0;
        }

        .content {
            width: 100%;

            p {
                font-size: 10px;
                font-weight: bold;
            }

            em, i {
                display: block;
            }
        }

        .label {
            color: $text-gray;
            font-size: 9px;
            width: 100%;
            margin: 10px 0 -10px 0;
            padding: 5px 10px;
            background: #f6f6f6;
            border-top: 1px solid #eaeaea;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            
            .name {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    .quick-add-family {
        overflow: hidden;
        max-height: 0;
        margin-bottom: 20px;
        @include transition(all 0.5s ease-in-out);

        &[data-visible="true"] {
            max-height: 35em;
        }
    }

    .family-list .edit-family-member {
        padding: 0 0 5px 0;
        margin: 0 0 5px 0;
    }

    .empty-family {
        text-align: center;
        font-size: 14px;
        color: $text-gray;
    }

    .family-content {

        h3 {
            text-align: center;
            color: $text-gray;
            font-size: 14px;
            margin: 3em 0 .75em 0;
        }
        > footer {
            margin-top: 40px;
            text-align: center;
        }
    }
}
