
@import "../../../../sass/imports";

.edit-ingredient-modal {
    .el-alert {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .prep-step, .ingredient-text, .food-unit-selector, .mapped-food {
        max-width: 400px;
        margin: 0 auto 10px auto;

        label {
            font-size: 14px;
        }

        input[type="text"] {
            font-size: 16px;
        }

        &:first-child {
            margin: 10px auto;
        }

        &:last-child {
            margin-top: auto;
        }
    }

    .mapped-food {
        > p {
            margin: 0px 0 0 12px;
        }

        .swap-btn {
            font-size: 16px;
        }
    }

    .food-loading {
        height: 76px;
        text-align: center;

        i {
            display: inline-block;
            font-size: 26px;
            color: $raspberry;
        }
    }

    .food-unit-selector .food-amount, .food-unit-selector .food-unit {
        width: 50%;
    }

    &.el-modal {
        .el-modal-ok-btn, .el-modal-cancel-btn {
            min-width: auto;

            @include min-screen(47em) {
                min-width: auto;
            }
        }
    }

    .combobox-container[data-above=true][data-state="true"] .combobox-dropdown {
        max-height: 15em;
    }

    .food-unit-selector {
        .error-message {
            display: flex;
            justify-content: center;
        }
    }
}

