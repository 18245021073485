
@import "../../../sass/imports";

.macros-pie-chart {

    .chart {
        width: 60px;
        height: 60px;
        display: inline-block;
        vertical-align: top;
        margin: 0 13px 0 16px;
    }

    .macros-legend {
        font-size: 12px;
        padding: 0 5px;
        display: inline-block;

        .color-key {
            font-size: 9px;
            padding: 3px;
            width: 20px;
        }

        .cho i {
            color: #e97a99;
        }

        .pro i {
            color: $mint-green3; // #2cd296
        }

        .fat i {
            color: $bluey-gray; // #a8acbe
        }

        li > span {
            width: 35px;
            display: inline-block;
        }

        .name {
            width: 45px;

            > span {
                font-weight: bold;
            }

            &[data-has-goal="true"] {
                width: 80px;
            }
        }
    }
}
