
@import "../../../../../sass/imports";
@import "../../../../../sass/pro-style-guide";

.patient-form {
    padding: 10px;

    @include min-screen(675px) {
        padding: 30px;
    }

    [data-error="true"] {
        @include box-shadow(0, 2px, 6px, 0, rgba(255, 0, 0, 0.17));
        border: solid 0.5px $error-border !important;
    }
    .el-nutrition-info-notifications {
        .el-pro-checkbox {
            display: inline-block;
            margin-bottom: 0;
            max-width: 80%;
        }
        .el-popup-nutrition-info-tooltip {
            vertical-align: middle;
            .dropdown-btn {
                border: none;
                background: none;
                i {
                    color: #5A6677;
                }
            }
            .icon-info {
                padding-left: 8px;
                font-size: 110%;
            }
            .popup-content {
                min-width: 200px;
                p {
                    font-size: 12px;
                }
                a:active, a:link, a:visited {
                    color: white;
                    text-decoration: underline;
                }
            }
        }
    }

    textarea, input[type="text"], input[type="password"], input[type="number"], input[type="tel"] {
        background-color: transparent;
        border: solid 0.5px $bluey-gray;
        font-size: 16px;
        color: darken($raspberry, 10%);
        padding: 4px 8px;
        -webkit-appearance: none;

        &:focus {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
        }

        &:disabled {
            opacity: 0.5;
        }

        @include placeholder {
            font-style: italic;
        }
    }

    input[type="tel"] {
        padding: 4px 8px 4px 40px;
    }

    .numeric-entry {
        text-align: right;
    }

    .checkbox-btn {
        position: relative;
        padding: 0 0 0 20px;
        font-size: 14px;
        color: $text-gray;
        border: none;
        background: none;
        text-align: left;
        margin: .75em;

        &:before {
            @include icon-font;
            content: $icon-checkbox-unchecked;
            font-size: 18px;

            position: absolute;
            top: 1px;
            left: 0;
        }

        &[data-checked="true"] {
            &:before {
                content: $icon-checkbox-checked1;
                color: $mint-green3;
            }

        }
    }

    .el-pro-checkbox {
        font-size: 16px;
        color: $text-gray;
    }

    .cover-image-form {
        padding: 20px 0;

        p {
            margin: 0;
            max-width: 500px;
            color: #203152;
            font-size: 16px;
        }

        > hr {
            width: calc(100% - 5px);
            opacity: 0.2;
            background: #86919C;
        }

        .cover-image-picker {
            li {
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
                border: none;
                border-radius: 8px;

                &[data-selected="true"] {
                    background: $raspberry;
                    filter: none;
                }
            }
        }
    }

    .checkbox {
        position: relative;
        display: block;
        margin: .5em 0;
        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            @include opacity(0);
        }
        span {
            display: inline-block;
            font-size: 14px;
            color: $text-gray;

            &:before {
                font-size: 12px;
                @include icon-font;
                color: white;
                display: inline-block;
                content: ' ';
                padding-top: .25em;
                padding-left: .25em;
                width: 20px;
                height: 20px;
                margin-right: .5em;
                @include transition(.66s color);
                vertical-align: middle;

                border: solid 0.5px $another-gray;
            }
        }
        input:checked + span {
            &:before {
                border: solid 0.5px #1d976b;
                background-color: $mint-green3;
                content: $icon-check;
            }
        }
    }

    .toggle-button {
        max-width: 120px;
        width: calc(50% - 20px);
        height: 60px;
        background-color: white;
        border: solid 0.5px $bluey-gray;
        position: relative;

        color: $text-gray;
        text-align: center;
        margin: 10px;

        i {
            font-size: 16px;
            display: block;
            color: $icon-inactive;
            @include transition(all .25s ease-in-out);
        }

        @include transition(all .25s ease-in-out);

        &:focus, &:hover {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
        }

        &[data-active="true"] {
            color: darken($raspberry, 10%);
            border: solid 0.5px darken($raspberry, 10%);

            i {
                color: darken($raspberry, 10%);
            }

            &:after {
                border: solid 0.5px darken($raspberry, 10%);
                background-color: $raspberry;
                content: $icon-check;
            }
        }

        &:after {
            position: absolute;
            top: 0;
            left: 0;

            @include icon-font;
            content: ' ';
            color: $text-gray;
            display: inline-block;
            vertical-align: middle;
            font-size: 12px;
            color: white;

            padding-top: .25em;
            padding-left: .25em;
            margin: 5px;
            width: 20px;
            height: 20px;
            border: solid 0.5px #e3e4e9;
        }
    }

    .toggle-button-short {
        border: 0.5px solid $bluey-gray;
        background-color: white;
        color: $bluey-gray;
        font-weight: bold;
        font-size: 12px;
        min-width: 100px;
        margin: 0 10px;
        padding: 10px;
        text-transform: uppercase;
        @include transition(all 0.25s ease-in-out);
        i {
            font-size: 15px;
            vertical-align: middle;
            display: inline-block;
            margin-right: .5em;
        }

        &[data-active="true"] {
            color: darken($raspberry, 10%);
            border: solid 0.5px darken($raspberry, 10%);

            i {
                color: darken($raspberry, 10%);
            }
        }

        &:focus {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
        }

        &:hover {
            color: white;
            background-color: $bluey-gray;
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));

            &[data-active="true"] {
                color: darken($raspberry, 20%);
                border: solid 0.5px darken($raspberry, 20%);
            }
        }
    }


    .with-label {
        display: inline-block;
        vertical-align: top;
        margin: 5px;

        @include min-screen(675px) {
            margin: 10px;
        }

        input {
            margin-top: 0px;
        }

        label {
            display: block;
            font-size: 10px;
            font-weight: bold;
            color: $text-gray;
            letter-spacing: 1px;
            text-transform: uppercase;
            white-space: nowrap;

            em {
                color: $inactive-text-gray;
                font-weight: normal;
            }
        }

        &[data-disabled="true"] {
            opacity: 0.5;
        }
    }

    .with-units {
        display: inline-block;
        margin: 0;
        width: 100%;
        input {
            width: calc(100% - 35px);
            margin-right: 5px;
        }

        label {
            display: inline-block;
            vertical-align: middle;
            font-size: 10px;
            font-weight: bold;
            color: $text-gray;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }

    .select-container {
        display: inline-block;
        border: solid 0.5px $bluey-gray;

        font-size: 16px;
        padding: 4px 8px;
        width: 100%;

        .placeholder {
            font-size: 10px;
            font-weight: bold;
            color: $text-gray;
            padding: 4px;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .value {
            font-size: 16px;
            color: darken($raspberry, 10%);

            em {
                color: $text-gray;
            }
        }

        .select-dropdown p {
            text-transform: uppercase;
            width: calc(100% - 32px);
            font-size: 10px;
            color: $text-gray;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .image-upload {
        position: relative;

        img {
            width: calc(100% - 20px);
            margin: 10px;
            @include box-shadow(0, 5px, 20px, 0, rgba(0, 0, 0, 0.1));
        }

        > footer {
            padding-top: 1px;
            text-align: center;
        }

        .icon-logo, .icon-female2, .icon-male2 {
            text-align: center;
            width: 200px;
            height: 200px;
            display: block;
            font-size: 85px;
            padding: 25px;
            color: $raspberry;
            margin: 10px auto;
            @include box-shadow(0, 5px, 20px, 0, rgba(0, 0, 0, 0.1));
        }

        .icon-female2, .icon-male2 {
            padding-top: 50px;
        }

        .icon-logo {
            height: 0;
            width: 200px;
            height: auto;
        }

        .delete-btn {
            position: absolute;
            top: 40px;
            right: 20px;

            border: none;
            background: $raspberry;
            color: rgba(255, 255, 255, 1);
            font-weight: bold;
            @include transition(all .2s ease-in-out);
            width: 26px;
            height: 26px;
            overflow: hidden;
            text-align: center;

            padding: 2px 5px;
            font-size: 16px;
            z-index: 5;

            &:hover {
                background: lighten($raspberry, 10%);
            }
        }
    }

    .sub-action-btn {
        @include outline-button($raspberry);
        @include border-radius(0);
        background-color: transparent;
        font-size: 10px;
        text-transform: uppercase;
        padding: 8px 10px;
        border-width: 0px;
        text-decoration: underline;
        text-align: center;
        margin: 5px;

        i {
            text-decoration: none;
        }

        .icon-lock {
            display: inline-block;
            margin: 0 5px;
        }
    }

    .warning {
        color: white;
        padding: 15px 20px;
        background-color: $text-gray;
        @include border-radius(5px);
        margin: 10px;
        font-size: 14px;
        text-align: left;
        font-weight: bold;

        em {
            font-weight: bold;
            font-size: 16px;
        }
    }

    .off-switch {
        position: relative;
        display: block;
        width: 100%;
        text-align: left;
        margin: 9px 0;
        color: $text-gray;
        font-size: 14px;
        border: none;
        background: none;
        padding: 0 60px 0 0;

        > span {
            position: absolute;
            top: 50%;
            right: 0;
            @include border-radius(30px);
            @include transform(translate(0, -50%));
            display: block;
            width: 45px;
            font-size: 9px;
            text-transform: uppercase;
            border: 1px solid $light-gray-border;
            padding: 2px 0 2px 19px;
            color: $bluey-gray;

            &:after {
                @include icon-font;
                position: absolute;
                top: 50%;
                @include transform(translate(0, -50%));
                @include transition(right .1s ease-in-out);
                font-size: 16px;
                content: $icon-bullet;
                right: 25px;
            }
        }

        &[data-active="true"] {
            > span {
                border: 1px solid $raspberry;
                padding: 2px 17px 2px 5px;
                color: white;
                background-color: $raspberry;

                &:after {
                    right: 0;
                    color: white;
                }
            }
        }
    }


}

.confirm-discard-changes {
    padding: 20px;

    h6 {
        color: $text-gray;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
    }

    p {
        margin-top: 10px;
        color: $text-gray;
        font-size: 14px;
        text-align: center;
    }
}
