
@import "../../../sass/imports";

.rw-combobox,
.rw-datetimepicker,
.rw-numberpicker,
.rw-dropdownlist {
    padding-right: 1.9em
}
.rw-combobox.rw-rtl,
.rw-datetimepicker.rw-rtl,
.rw-numberpicker.rw-rtl,
.rw-dropdownlist.rw-rtl {
    padding-right: 0;
    padding-left: 1.9em
}
.rw-combobox>.rw-input,
.rw-datetimepicker>.rw-input,
.rw-numberpicker>.rw-input,
.rw-dropdownlist>.rw-input {
    width: 100%;
    border: none;
    outline: 0
}
.rw-combobox>.rw-input::-moz-placeholder,
.rw-datetimepicker>.rw-input::-moz-placeholder,
.rw-numberpicker>.rw-input::-moz-placeholder,
.rw-dropdownlist>.rw-input::-moz-placeholder {
    color: #999;
    opacity: 1
}
.rw-combobox>.rw-input:-ms-input-placeholder,
.rw-datetimepicker>.rw-input:-ms-input-placeholder,
.rw-numberpicker>.rw-input:-ms-input-placeholder,
.rw-dropdownlist>.rw-input:-ms-input-placeholder {
    color: #999
}
.rw-combobox>.rw-input::-webkit-input-placeholder,
.rw-datetimepicker>.rw-input::-webkit-input-placeholder,
.rw-numberpicker>.rw-input::-webkit-input-placeholder,
.rw-dropdownlist>.rw-input::-webkit-input-placeholder {
    color: #999
}
.rw-select {
    position: absolute;
    width: 1.9em;
    height: 100%;
    right: 0
}
.rw-select.rw-btn,
.rw-select>.rw-btn {
    height: 100%;
    vertical-align: middle;
    outline: 0
}
.rw-rtl .rw-select {
    left: 0;
    right: auto
}
.rw-multiselect,
.rw-combobox input.rw-input,
.rw-datetimepicker input.rw-input,
.rw-numberpicker input.rw-input {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}
.rw-combobox:active,
.rw-datetimepicker:active,
.rw-dropdownlist:active,
.rw-header>.rw-btn:active,
.rw-numberpicker .rw-btn.rw-state-active,
.rw-combobox:active.rw-state-focus,
.rw-datetimepicker:active.rw-state-focus,
.rw-dropdownlist:active.rw-state-focus,
.rw-header>.rw-btn:active.rw-state-focus,
.rw-numberpicker .rw-btn.rw-state-active.rw-state-focus {
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
}
.rw-combobox:hover,
.rw-datetimepicker:hover,
.rw-numberpicker:hover,
.rw-dropdownlist:hover {
}
.rw-btn,
.rw-input {
    color: inherit;
    font: inherit;
    margin: 0
}
button.rw-input {
    overflow: visible
}
button.rw-input,
select.rw-input {
    text-transform: none
}
button.rw-input,
html input[type="button"].rw-input,
input[type="reset"].rw-input,
input[type="submit"].rw-input {
    -webkit-appearance: button;
    cursor: pointer
}
button[disabled].rw-input,
html input[disabled].rw-input {
    cursor: not-allowed
}
button.rw-input::-moz-focus-inner,
input.rw-input::-moz-focus-inner {
    border: 0;
    padding: 0
}
.rw-sr {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}
.rw-widget,
.rw-widget * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}
.rw-widget:before,
.rw-widget *:before,
.rw-widget:after,
.rw-widget *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}
.rw-i {
    display: inline-block;
    font-family: 'eatlove';
    font-style: normal;
    font-weight: normal;
    line-height: 1em;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}
.rw-i-caret-down:before, .rw-i-chevron-down:before {
    content: $icon-chevron-down;
}
.rw-i-caret-up:before, .rw-i-chevron-up:before {
    content: $icon-chevron-up;
}
.rw-i-caret-left:before, .rw-i-chevron-left:before {
    content: $icon-chevron-left;
}
.rw-i-caret-right:before, .rw-i-chevron-right:before {
    content: $icon-chevron-right;
}
.rw-i-clock-o:before {
    content: $icon-clock1;
}
.rw-i-calendar:before {
    content: $icon-calendar5;
}
.rw-widget {
    outline: 0;
    -moz-background-clip: border-box;
    -webkit-background-clip: border-box;
    background-clip: border-box
}
.rw-btn {
    color: #333;
    line-height: 2.286em;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid transparent;
    padding: 0;
    white-space: nowrap
}
.rw-rtl {
    direction: rtl
}
.rw-input {
    background-color: transparent;
    border: solid 0.5px $bluey-gray;
    font-size: 16px;
    color: darken($raspberry, 10%);
    padding: 4px 8px;
}
.rw-input[disabled] {
    cursor: not-allowed;
}
.rw-input[readonly] {
    cursor: not-allowed
}
.rw-i.rw-loading {
    width: 16px;
    height: 100%
}
.rw-i.rw-loading:before {
    content: ""
}
.rw-loading-mask {
    border-radius: 4px;
    position: relative
}
.rw-loading-mask:after {
    content: '';
    position: absolute;
    background-color: #fff;
    opacity: .7;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}
.rw-state-focus {
    @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
}
.rw-state-selected {
    border: #adadad 1px solid;
    color: #333
}
.rw-state-disabled {
    opacity: 0.5;
}
.rw-btn,
.rw-dropdownlist {
    cursor: pointer
}
.rw-btn[disabled],
.rw-state-disabled .rw-btn,
.rw-state-readonly .rw-btn {
    -webkit-box-shadow: none;
    box-shadow: none;
    pointer-events: none;
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: .65
}
ul.rw-list,
ul.rw-selectlist {
    margin: 0;
    padding-left: 0;
    list-style: none;
    padding: 5px 0;
    overflow: auto;
    outline: 0;
    height: 100%
}
ul.rw-list>li.rw-list-optgroup,
ul.rw-selectlist>li.rw-list-optgroup {
    font-weight: bold
}
ul.rw-list>li.rw-list-option,
ul.rw-selectlist>li.rw-list-option {
    cursor: pointer;
    border: 1px solid transparent;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px
}
ul.rw-list>li.rw-list-option:hover,
ul.rw-selectlist>li.rw-list-option:hover {
    background-color: #e6e6e6;
    border-color: #adadad
}
ul.rw-list>li.rw-list-option.rw-state-focus,
ul.rw-selectlist>li.rw-list-option.rw-state-focus {
    background-color: #fff;
    border: #66afe9 1px solid;
    color: #333
}
ul.rw-list>li.rw-list-option.rw-state-selected,
ul.rw-selectlist>li.rw-list-option.rw-state-selected {
    border: #adadad 1px solid;
    color: #333
}
ul.rw-list.rw-list-grouped>li.rw-list-optgroup {
    padding-left: 10px
}
ul.rw-list.rw-list-grouped>li.rw-list-option {
    padding-left: 20px
}
.rw-widget {
    position: relative
}
.rw-open.rw-widget,
.rw-open>.rw-multiselect-wrapper {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}
.rw-combobox .rw-list,
.rw-datetimepicker .rw-list,
.rw-numberpicker .rw-list,
.rw-dropdownlist .rw-list,
.rw-multiselect .rw-list {
    max-height: 200px;
    height: auto
}
.rw-widget {
    background-color: #fff;
    border: solid 0.5px $bluey-gray;
}
.rw-widget .rw-input {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px
}
.rw-rtl.rw-widget .rw-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px
}
.rw-widget>.rw-select {
    border-left: #ccc 1px solid
}
.rw-rtl.rw-widget>.rw-select {
    border-right: #ccc 1px solid;
    border-left: none
}
.rw-widget.rw-state-focus,
.rw-widget.rw-state-focus:hover {
}
.rw-widget.rw-state-readonly,
.rw-widget.rw-state-readonly>.rw-multiselect-wrapper {
    cursor: not-allowed
}
.rw-widget.rw-state-disabled,
.rw-widget.rw-state-disabled:hover,
.rw-widget.rw-state-disabled:active {
    -webkit-box-shadow: none;
}
.rw-combobox,
.rw-datetimepicker,
.rw-numberpicker,
.rw-dropdownlist {
    padding-right: 1.9em
}
.rw-combobox.rw-rtl,
.rw-datetimepicker.rw-rtl,
.rw-numberpicker.rw-rtl,
.rw-dropdownlist.rw-rtl {
    padding-right: 0;
    padding-left: 1.9em
}
.rw-combobox>.rw-input,
.rw-datetimepicker>.rw-input,
.rw-numberpicker>.rw-input,
.rw-dropdownlist>.rw-input {
    width: 100%;
    border: none;
    outline: 0
}
.rw-combobox>.rw-input::-moz-placeholder,
.rw-datetimepicker>.rw-input::-moz-placeholder,
.rw-numberpicker>.rw-input::-moz-placeholder,
.rw-dropdownlist>.rw-input::-moz-placeholder {
    color: #999;
    opacity: 1
}
.rw-combobox>.rw-input:-ms-input-placeholder,
.rw-datetimepicker>.rw-input:-ms-input-placeholder,
.rw-numberpicker>.rw-input:-ms-input-placeholder,
.rw-dropdownlist>.rw-input:-ms-input-placeholder {
    color: #999
}
.rw-combobox>.rw-input::-webkit-input-placeholder,
.rw-datetimepicker>.rw-input::-webkit-input-placeholder,
.rw-numberpicker>.rw-input::-webkit-input-placeholder,
.rw-dropdownlist>.rw-input::-webkit-input-placeholder {
    color: #999
}
.rw-select {
    position: absolute;
    width: 1.9em;
    height: 100%;
    right: 0
}
.rw-select.rw-btn,
.rw-select>.rw-btn {
    height: 100%;
    vertical-align: middle;
    outline: 0
}
.rw-rtl .rw-select {
    left: 0;
    right: auto
}
.rw-multiselect,
.rw-combobox input.rw-input,
.rw-datetimepicker input.rw-input,
.rw-numberpicker input.rw-input {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}
.rw-combobox:active,
.rw-datetimepicker:active,
.rw-dropdownlist:active,
.rw-header>.rw-btn:active,
.rw-numberpicker .rw-btn.rw-state-active,
.rw-combobox:active.rw-state-focus,
.rw-datetimepicker:active.rw-state-focus,
.rw-dropdownlist:active.rw-state-focus,
.rw-header>.rw-btn:active.rw-state-focus,
.rw-numberpicker .rw-btn.rw-state-active.rw-state-focus {
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
}
.rw-combobox:hover,
.rw-datetimepicker:hover,
.rw-numberpicker:hover,
.rw-dropdownlist:hover {
}
.rw-dropdownlist.rw-state-disabled,
.rw-dropdownlist.rw-state-readonly {
    cursor: not-allowed
}
.rw-dropdownlist>.rw-input {
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0
}
.rw-dropdownlist>.rw-select,
.rw-dropdownlist>.rw-select.rw-rtl {
    border-width: 0
}
.rw-numberpicker .rw-btn {
    display: block;
    height: 1.143em;
    line-height: 1.143em;
    width: 100%;
    border-width: 0
}
.rw-popup {
    position: absolute;
    -webkit-box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border: #ccc 1px solid;
    background: #fff;
    padding: 2px;
    overflow: auto;
    margin-bottom: 10px;
    left: 10px;
    right: 10px
}
.rw-popup-container {
    position: absolute;
    top: 100%;
    margin-top: 1px;
    z-index: 1005;
    left: -11px;
    right: -11px
}
.rw-popup-container.rw-calendar-popup {
    right: auto;
    width: 100%;
    max-width: 350px;
}
.rw-datetimepicker .rw-btn {
    width: 1.8em
}
.rw-datetimepicker.rw-has-neither {
    padding-left: 0;
    padding-right: 0
}
.rw-datetimepicker.rw-has-neither .rw-input {
    border-radius: 4px
}
.rw-datetimepicker.rw-has-both {
    padding-right: 3.8em
}
.rw-datetimepicker.rw-has-both.rw-rtl {
    padding-right: 0;
    padding-left: 3.8em
}
.rw-datetimepicker.rw-has-both>.rw-select {
    width: 3.8em;
    height: 100%
}
.rw-calendar {
    background-color: #fff
}
.rw-calendar thead>tr {
    border-bottom: 2px solid #ccc
}
.rw-calendar .rw-header {
    padding-bottom: 5px
}
.rw-calendar .rw-header .rw-btn-left,
.rw-calendar .rw-header .rw-btn-right {
    width: 12.5%
}
.rw-calendar .rw-header .rw-btn-view {
    width: 75%;
    background-color: #eee;
    border-radius: 4px
}
.rw-calendar .rw-header .rw-btn-view[disabled] {
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed
}
.rw-calendar-grid {
    height: 14.28571429em;
    table-layout: fixed;
    width: 100%
}
.rw-calendar-grid th {
    text-align: right;
    padding: 0 .4em 0 .1em;
    white-space: nowrap;
}
.rw-calendar-grid .rw-btn {
    width: 100%;
    text-align: right
}
.rw-calendar-grid td .rw-btn {
    border-radius: 4px;
    padding: 0 .4em 0 .1em;
    outline: 0
}
.rw-calendar-grid td .rw-btn:hover {
    background-color: #e6e6e6
}
.rw-calendar-grid td .rw-btn.rw-off-range {
    color: #b3b3b3
}
.rw-calendar-grid.rw-nav-view .rw-btn {
    padding: .25em 0 .3em;
    display: block;
    overflow: hidden;
    text-align: center;
    white-space: normal
}
.rw-selectlist {
    padding: 2px
}
.rw-selectlist>ul {
    height: 100%;
    overflow: auto
}
.rw-selectlist>ul>li.rw-list-option {
    position: relative;
    min-height: 27px;
    cursor: auto;
    padding-left: 5px
}
.rw-selectlist>ul>li.rw-list-option>label>input {
    position: absolute;
    margin: 4px 0 0 -20px
}
.rw-selectlist>ul>li.rw-list-option>label {
    padding-left: 20px;
    line-height: 1.423em;
    display: inline-block
}
.rw-selectlist.rw-rtl>ul>li.rw-list-option {
    padding-left: 0;
    padding-right: 5px
}
.rw-selectlist.rw-rtl>ul>li.rw-list-option>label>input {
    margin: 4px -20px 0 0
}
.rw-selectlist.rw-rtl>ul>li.rw-list-option>label {
    padding-left: 0;
    padding-right: 20px
}
.rw-selectlist.rw-rtl>ul>li.rw-list-option {
    padding-left: 0;
    padding-right: 5px
}
.rw-selectlist.rw-rtl>ul>li.rw-list-option>label>input {
    margin: 4px -20px 0 0
}
.rw-selectlist.rw-rtl>ul>li.rw-list-option>label {
    padding-left: 0;
    padding-right: 20px
}
.rw-selectlist.rw-state-disabled>ul>li:hover,
.rw-selectlist.rw-state-readonly>ul>li:hover {
    background: none;
    border-color: transparent
}
.rw-multiselect {
    background-color: #fff
}
.rw-multiselect:hover {
    border-color: #adadad
}
.rw-multiselect-wrapper {
    border-radius: 4px;
    position: relative;
    cursor: text
}
.rw-multiselect-wrapper:before,
.rw-multiselect-wrapper:after {
    content: " ";
    display: table
}
.rw-multiselect-wrapper:after {
    clear: both
}
.rw-multiselect-wrapper i.rw-loading {
    position: absolute;
    right: 3px
}
.rw-multiselect-wrapper>.rw-input {
    float: left;
    outline: 0;
    border-width: 0;
    line-height: normal;
    width: auto
}
.rw-multiselect-wrapper>.rw-input::-moz-placeholder {
    color: black;
    opacity: 1
}
.rw-multiselect-wrapper>.rw-input:-ms-input-placeholder {
    color: black
}
.rw-multiselect-wrapper>.rw-input::-webkit-input-placeholder {
    color: black
}
.rw-state-readonly>.rw-multiselect-wrapper,
.rw-state-disabled>.rw-multiselect-wrapper {
    cursor: not-allowed
}
.rw-rtl .rw-multiselect-wrapper>.rw-input {
    float: right
}
.rw-multiselect-wrapper .rw-btn {
    outline: 0;
    font-size: 115%;
    line-height: normal
}
.rw-multiselect-create-tag {
    border-top: 1px #ccc solid;
    padding-top: 5px;
    margin-top: 5px
}
.rw-multiselect-taglist {
    margin: 0;
    padding-left: 0;
    list-style: none;
    padding-right: 0
}
.rw-multiselect-taglist>li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px
}
.rw-multiselect-taglist>li {
    float: left;
    display: inline-block;
    margin: 1px;
    padding: .214em .15em .214em .4em;
    line-height: 1.4em;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    border-radius: 3px;
    border: 1px solid #ccc;
    background-color: #ccc;
    cursor: pointer
}
.rw-multiselect-taglist>li.rw-state-focus {
    background-color: #fff;
    border: #66afe9 1px solid;
    color: #333
}
.rw-multiselect-taglist>li.rw-state-readonly,
.rw-multiselect-taglist>li.rw-state-disabled,
.rw-multiselect.rw-state-readonly .rw-multiselect-taglist>li,
.rw-multiselect.rw-state-disabled .rw-multiselect-taglist>li {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: .65
}
.rw-rtl .rw-multiselect-taglist>li {
    float: right
}

.rw-calendar-header {
    display: flex;
    align-content: stretch;
    justify-content: space-between;
}

.rw-calendar-btn-left, .rw-calendar-btn-right {
    padding: 0 10px;
}
