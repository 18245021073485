
// Typography Sizes
//-- This only relates to font-sizes

@mixin display-one {
    font-size: 4.25em;  //68px
    line-height: 1.1;
}
@mixin display-two {
    font-size: 3.75em; //60px
    line-height: 1.1;
}
@mixin display-three {
    font-size: 2.5em; //40px
    line-height: 1.1;
}
@mixin display-four {
    font-size: 2.18em; //35px
    line-height: 1.15;
}
@mixin headline {
    font-size: 1.875em; //30px
    line-height: 1.15;
}
@mixin title {
    font-size: 1.5em; //24px
    line-height: 1.3;
}
@mixin heading {
    font-size: 1.25em; //20px
    line-height: 1.3;
}
@mixin sub-head {
    font-size: 1.125em; //18px
    line-height: 1.3;
}
@mixin body-one {
    font-size: .875em;  //14px
    line-height: 1.3;
}
@mixin body-two {
    font-size: 1em; //16px
    line-height: 1.3;
}
@mixin caption {
    font-size: .75em; //12px
    line-height: 1.3;
}

