@import "../../../../sass/imports";

.plan-card {
    cursor: pointer;
    position: relative;
    z-index: 0;
    text-align: left;

    .plan-card-fan1, .plan-card-fan2 {
        @include box-shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.45));
        border: 5px solid white;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .plan-card-fan1 {
        background-color: #9da1b1;
        @include transform(rotate(6deg));
        z-index: -2;
    }

    .plan-card-fan2 {
        background-color: #9da1b1;
        @include transform(rotate(3deg));
        z-index: -1;
    }

    .plan-card-image-container {
        @include box-shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.45));
        border: 5px solid white;
        position: relative;
        min-height: 150px;
        overflow: hidden;
        z-index: 0;
    }

    // Covers the entire div, we'll put a non-blurry one on top
    .plan-card-image, .plan-card-image-blurry {
        background-color: $grayscale5;

        position: absolute;
        top: -1%;
        bottom: -1%;
        left: -1%;
        right: -1%;

        background-size: 100%;
        background-repeat: no-repeat;
        background-position-y: 0px;
    }
    .plan-card-image {
        // bottom: 85px;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .plan-card-contents {
        padding-top: 125%;
        width: 100%;
    }

    // text over the recipe image (title, merchant, meal type)
    .plan-card-text {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 85px;
        z-index: 1;
        cursor: pointer;
        padding: 5px;
        background-color: rgba(0, 0, 0, 0.5);

        > div {
            width: 100%;
            // height: 4em;
            overflow: hidden;
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
        }

        h3 {
            width: calc(100% - 6px);
            font-size: 11px;
            font-weight: normal;
            line-height: 1.07;
            text-align: center;
            color: #ffffff;
            margin: 5px 3px;
            max-height: 2.05em;
            overflow: hidden;
        }

        h6 {
            display: none;
            font-size: 12.5px;
            font-weight: 800;
            line-height: 1.2;
            letter-spacing: 0.8;
            color: white;

            white-space: nowrap;
            text-overflow: ellipsis;
            text-transform: capitalize;
            overflow: hidden;
        }
    }

    .submenu-dropdown {
        z-index: 8;
    }

    .publisher-submenu-btn {
        position: absolute;
        z-index: 8;
        bottom: 0;
        right: 0;
        border: solid 1px rgba(255, 255, 255, 0.3);
        background: none;
        text-align: center;

        @include border-radius(100%);

        width: 30px;
        height: 30px;
        margin: 10px;

        i {
            color: white;
            font-size: 13px;
        }

        &[data-boarded="true"] {
            i {
                color: $raspberry;
            }
        }
    }


    .favorite-btn {
        position: absolute;
        z-index: 8;
        top: 0;
        right: 0;
        border: none;
        background: none;
        text-align: center;

        @include border-radius(100%);

        width: 30px;
        height: 30px;
        margin: 5px;

        i {
        color: #a8acbe;
            font-size: 18px;
        }

        &[data-boarded="true"] {
            i {
                color: $raspberry;
            }
        }
    }

    .plan-size-info {
        text-transform: uppercase;
        color: white;
        font-size: 14px;

        text-align: center;
    }

    > button {
        @include flat-button(#2cd296);
        @include border-radius(0);
        width: 100%;
        display: block;
        padding: 1em;

        font-size: 10px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: 0.8px;
        text-align: center;
        color: #ffffff;
    }

    &[data-short="true"] {
        .plan-card-contents {
            padding-top: 87%;
        }
    }

    &[data-advanced="true"] {
        .plan-card-image {
            bottom: 112px;
        }

        .plan-card-text {
            height: 112px;
            padding: 10px 0 0 10px;

            > div {
                height: auto;
                position: relative;
                @include transform(none);
                transform: none !important;
                top: auto;
                left: auto;
            }

            h3 {
                text-align: left;
                top: auto;
                left: auto;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-bottom: .25em;

            }

            h6 {
                display: block;
            }
        }

        .plan-size-info, .patient-information {
            display: block;
        }

        .plan-size-info {
            font-size: 18px;
            margin-top: .7em;
            font-weight: 800;
            text-align: left;
            line-height: 1.2;

            &:before {
                width: 50px;
                border-bottom: solid 0.5px #ffffff;
                opacity: 0.3;
                display: block;
                content: ' ';
                margin-bottom: .7em;
            }
        }
    }

    .delete-btn {
        position: absolute;
        top: 10px;
        right: 10px;

        border: none;
        background: $raspberry;
        color: rgba(255, 255, 255, 1);
        font-weight: bold;
        @include transition(all .2s ease-in-out);
        width: 26px;
        height: 26px;
        overflow: hidden;
        text-align: center;

        padding: 2px 5px;
        font-size: 16px;
        z-index: 15;

        &:hover {
            background: lighten($raspberry, 10%);
        }
    }

    .recommend-plan-favorite {
        position: absolute;
        right: -15px;
        top: 80px;
        z-index: 10;
    }

}
