

@mixin text-input($input-color: $grayscale8) {
    width: 100%;
    font-size: 1em;
    line-height: 2;
    padding: .25em .5em;
    border: 1px solid $grayscale3;
    color: $input-color;
    @include appearance(none);
    @include placeholder() {
        color: $grayscale4;
        font-style: italic;
    }
}

@mixin search-input(){

}

@mixin placeholder() {
  &::-webkit-input-placeholder { @content }
  &:-moz-placeholder { @content }
  &::-moz-placeholder { @content } //for newer versions of Firefox
  &:-ms-input-placeholder { @content }
}
