
@import "../../../../sass/imports";

.filter-estimator-modal {
    position: absolute;
    top: 0px;
    left: 50%;
    @include transform(translate(-50%, 0));
    background-color: white;

    max-width: 500px !important;
    max-height: 100vh;
    overflow-y: auto;
    width: 100%;
}

.filter-estimator-container {

    .collapsible {
        max-height: 0;
        overflow: hidden;

        @include transition(all 0.33s ease-in-out);

        &[data-expanding="true"] {
            max-height: 50em;
        }

        &[data-opened="true"] {
            overflow: visible;
        }
    }

    .calc-energy-btn {
        border: none;
        background: none;
        color: $orange3;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        border-width: 0px;
        text-decoration: none;
        text-align: center;
        margin-top: 5px;
        margin-left: 5px;
    }

    .el-alert {
        margin: 0 0 20px 0;
        display: block;
    }

    .meal-types-container {
        padding: 10px 5px;

        .toggle-btn {
            margin: 0 10px 10px 0
        }
    }

    .conditions {
        width: calc(100% - 20px);
    }

    .date-selector-container {
        margin: 5px 5px 0 0;
        text-align: left;
        display: flex;
    }

    .target-energy-kcal {
        width: calc(75% - 20px);
    }

    .units-mode {
        width: calc(50% - 20px);
        margin: 33px 0 0 0;
    }

    .height {
        width: calc(50% - 20px);
    }

    .height-feet {
        margin: 0 10px 0 0;
        width: calc(60% - 10px);
    }

    .height-inches {
        width: 40%;
    }

    .activity-level {
        width: calc(50% - 20px);
    }
}
