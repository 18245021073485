@import "../../../sass/imports";

.avoidances-selector {
    border: 1px solid transparent;
    text-align: center;
    color: $text-navy;

    @import "../../pro/components/Widgets/Combobox.scss";

    .select-bubbles {
        margin: 10px 0 0 0;
    }

    .add-avoided {
        text-align: center;
        margin: 15px 0 0 0;

        .combobox-container {
            width: 100%;
            border: solid 0.5px $bluey-gray;
            @include border-radius(50px);

            .option {
                padding: 8px 10px;
            }

            .type-ahead {
                box-shadow: none;
                border: none;
                padding: 8px 16px;

                background: transparent !important;
                border: none !important;
                box-shadow: none !important;
            }

            p {
                color: $text-navy;
                font-size: 10px;
                padding: 0 5px 5px;
            }
        }

        > .with-label {
            display: block;
            margin: auto;
            max-width: 275px;
            text-align: left;
            width: calc(100% - 10px);
            opacity: 0;
            overflow: hidden;
            max-height: 0;
            @include transition(max-height 0.25s ease-in-out);
        }

        .sub-action-btn {
            text-decoration: none;
        }
        
        &[data-is-pro="true"] .sub-action-btn {
            @include outline-button($raspberry);
        }

        &[data-show-search="true"] {
            .sub-action-btn {
                display: none;
            }

            > .with-label {
                opacity: 1;
                max-height: 100px;
                overflow: visible;
            }
        }
    }
}

.avoidances-modal-container {
    width: 100vw;

    .with-label {
        margin-top: 20px;
    }

    .el-medium-btn {
        margin: 5px;
    }

    .spinner-container {
        text-align: center;

        i {
            display: inline-block;
            color: $el-raspberry;
            font-size: 40px;
        }
    }

    .avoidances-disclaimer {
        font-size: 12px;
        margin-top: 10px;

        &[data-center="true"] {
            text-align: center;
        }

        .icon-spinner2 {
            display: inline-block;
            color: $el-raspberry;
            font-size: 20px;
        } 
    }

    .el-labeled-input {
        input {
            @include placeholder {
                color: $el-grayish-blue3;
            }
        }
    }

    .avoidance-search-results {
        margin-top: 20px;
        padding-bottom: 100px;

        @include min-screen(47em) {
            height: 300px;
        }

        .no-resuls-found {
            margin: 20px auto;
            display: block;
            text-align: center;
        }

        .result-item-container {
            cursor: pointer;
            color: $el-raspberry;

            &[data-selected="true"] {
                background-color: $el-grayish-blue2;

                .result-item {
                    background-color: $el-grayish-blue2;
                }
            }

            &[data-unavoidable="true"] {
                cursor: auto;
                color: $el-grayish-blue;
            }

            .result-item {
                position: relative;
                padding-left: 25px;
                display: inline-block;
                width: 100%;

                $max-levels: 10; // Adjust as needed

                @for $i from 1 through $max-levels {
                    &[data-indents="#{$i}"] {
                        padding-left: ($i * 30px) + 25px;
                    }
                }

                i {

                    @for $i from 1 through $max-levels {
                        &[data-indents="#{$i}"] {
                            padding-left: ($i * 30px);
                        }
                    }

                    position: absolute;
                    top: 50%;
                    left: 0;
                    @include transform(translateY(-50%));
                    font-size: 16px;

                    &.icon-lock {
                        position: static;
                        margin-left: 3px;
                    }
                }
                
            }
        }
    }
}

