
@import "../../../sass/imports";

.generic-tabs {
    .tabs {
        position: relative;

        border-bottom: solid 1px #e2e3e9;
        display: table;
        width: 100%;
        margin: auto;
        text-align: left;
        color: $el-grayish-blue3;

        > div {
            display: inline-block;
        }
    }

    .tab-btn {
        display: table-cell;
        border: none;
        background: none;
        padding: 1.75em 1.5em;
        position: relative;
        opacity: 0.3;

        // make it fit on iPhone5
        @include max-screen(320px) {
            padding-left: .3em;
            padding-right: .3em;
        }

        font-size: 11px;
        font-weight: bold;
        letter-spacing: 0.9px;
        text-align: center;
        text-transform: uppercase;

        &:after {
            position: absolute;
            right: 0;
            content: ' ';
            border-right: 1px solid #e2e3e9;
            height: 15px;
        }

        &:last-child:after {
            display: none;
        }
    }

    .active-tab-btn {
        border-bottom: solid 3px #a30063;
        color: $el-grayish-blue3;
        opacity: 1;
    }

    .tab-contents {
        margin-top: 1em;

        > div {
            display: none;
            clear: both;
        }

        > .active {
            display: block;
        }
    }

    @include max-screen(47em) {
        .tab-contents {
            margin: 1em;
        }
    }
}

.sub-navigation-tabs {
    .tabs {
        width: calc(100% - 40px);
        max-width: calc(100vw - 40px);
        margin: 0px 20px 10px 20px;
        background-color: rgba(255, 255, 255, 0.9);
        border: solid 0.5px rgba(0, 0, 0, 0.05);
        white-space: nowrap;
        display: block;
        overflow: auto;

        position: relative;
        z-index: 20;
        top: auto; left: auto;
        @include transform(translateX(0%));

        > div {
            display: inline-block;
        }

        @include border-radius(4px);
        @include box-shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.1));

        a {
            text-decoration: none;
        }

        @include min-screen(960px) {
            margin: 0px auto 20px auto;
            max-width: 940px;
        }

        h2 {
            font-weight: bold;
            padding: 11px 20px 11px 40px;
            min-width: 33%;
            text-align: center;
            font-size: 14px;
            text-transform: uppercase;
            vertical-align: middle;
            color: $text-navy;

            position: relative;
            max-width: calc(100vw - 40px);

            button {
                position: absolute;
                top: 50%;
                left: 0;
                @include transform(translateY(-50%));
            }
        }
    }

    .tab-contents {
        margin: 1em;
    }
}

.pill-tabs {
    > header {
        text-align: center;
    }

    .tabs {
        margin: 0 auto 1em auto;
        @include border-radius(.25em);
        border: 1px solid $el-grayish-blue3;

        display: inline-flex;

        > div {
            border-right: 1px solid $el-grayish-blue;

            @include transition(background-color 0.25s ease-in-out);
            &[data-status="false"] {
                background-color: transparent;
                button { color: $el-grayish-blue3 };

                &:hover {
                    background-color: #ddd;
                }
            }
            &[data-status="true"] {
                background-color: $el-grayish-blue3;
                button { color: white; }

                &:hover {
                    background-color: lighten($el-grayish-blue3, 10%);
                }
            }
        }

        button {
            border: 0;
            background-color: transparent;
            display: block;
            width: 100%;
            font-size: 12px;
            text-align: center;
            padding: 3px 11px;
            line-height: 1.5;
            @include transition(.33s all);
            text-transform: uppercase;
        }

        > div:first-child {
            button { @include border-radius(.2em 0 0 .2em); }
        }
        > div:last-child {
            border-right: 0;
            button { @include border-radius(0 .2em .2em 0); }
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .tab-btn {
        border: none;
        border-right: 1px solid $el-grayish-blue3;
        padding: 2px 20px;
        background: white;
        font-size: 13px;
        @include transition(all .25s ease-in-out);

        &.active-tab-btn {
            color: white;
            background-color: $el-grayish-blue3;

            &:hover {
                background-color: lighten($el-grayish-blue3, 10%);
            }
        }

    }


    .tab-contents {
        margin-top: 1em;

        > div {
            display: none;
            clear: both;
        }

        > .active {
            display: block;
        }
    }

}
