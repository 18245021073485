@import "../../../sass/imports";

.config-error, .config-warning {
    width: calc(100% - 20px);
    max-width: 550px;
    margin: 10px auto;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.7);
    @include border-radius(4px);
    position: relative;

    ul {
        padding-left: 80px;
    }

    li {
        text-align: left;
        color: $orange3;
        font-size: 14px;
        margin: .5em 0;
        margin-left: 50px;
        font-weight: bold;
    }

    &:before {
        @include icon-font;
        content: $icon-warning1;
        color: $orange3;
        font-size: 40px;
        position: absolute;
        top: 50%;
        left: 0;
        padding: 10px;
        @include transform(translateY(-50%));
    }
}

.config-warning {
    background-color: transparent;
    padding: 0;

    li {
        margin-left: 70px;
    }
}


.matching-dishes {
    text-align: center;

    li {
        display: inline-block;
        margin: 5px;
        font-size: 14px;
        font-weight: bold;
    }

    a {
        color: $raspberry;
    }
}
