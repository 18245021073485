
@import "../../../sass/imports";

.publisher-block {
    padding: 1em 0;
    min-width: 250px;
    text-align: center;
    @media print {
        display: none;
    }
    > .inner-container {
        max-width: 1000px;
    }
    h3 {
        @include title;
        font-weight: 500;
        color: $grayscale8;
        margin-bottom: .5em;
    }
    p {
        font-size: 1em;
        line-height: 1.1;
        color: $grayscale7;
    }
    .read-more {
        color: $mint-green3;
        white-space: nowrap;
        margin-left: .25em;
        &:after {
            @include icon-font;
            content: $icon-chevron-right-double;
            font-size: .65em;
            line-height: 2;
            margin-left: .25em;
        }
    }
    @include min-screen(47em) {
        text-align: left;
    }
}

.publisher-container {
    img {
        @include fluid-image;
        @include border-radius(100%);
        width: 80%;
        max-width: 250px;
        max-height: 250px;
        margin: auto;
    }

    @include min-screen(47em) {
        display: table;
        box-sizing: border-box;
        table-layout: fixed;

        > div {
            vertical-align: top;
        }

        > div:first-child {
            display: table-cell;
            width: 25%;
        }

        > div:last-child {
            display: table-cell;
            width: 75%;
            padding: 0 2% 0.5em 2%;
        }

        img {
            margin-left: 0;
            max-width: 80%;
        }
    }

    &[data-alt="true"] {
        > div {
            vertical-align: middle;
        }

        img {
            @include border-radius(0);
        }
    }
}
