
@import "../../../../../sass/imports";

.try-it-out-modal {
    @include min-screen(470px) {
        width: 450px !important;
        bottom: auto;
        min-height: 650px !important;
    }

    @include max-screen(469px) {
        width: auto !important;
        min-height: 650px !important;
        bottom: auto;
        left: 10px; right: 10px;
        @include transform(translate(0%));
    }

    @include max-screen-height(700px) {
        min-height: auto !important;
        top: 10px;
        bottom: 10px;
    }

}

.try-it-out-modal-container {

    &.el-modal2-container {
        min-width: 375px;

        > header {     
            h2 {
                padding: 25px 50px 10px;
            }
        }

        .mode-toggle {
            background-color: #f6f7f7;
            &:hover {
                background-color: $raspberry;
            }
        }
    }

    .el-modal2-body-container {
        padding: 10px 5px 20px;
        height: calc(100vh - 160px);

        &[data-prescription="true"] {
            height: calc(100vh - 190px);
        }        
        
    }

    > footer {


        .el-modal-ok-btn {
            padding: 6px 15px;

            i {
                display: inline-block;
                padding: 0 8px;
                vertical-align: middle;
            }

            &[data-flash="1"], &[data-flash="5"] {
                background-color: $mint-green3;
                @include transform(scale(1.1));
            }

            &[data-flash="3"] {
                background-color: $raspberry;
                @include transform(scale(1.1));
            }
        }
    }

    &.editor-modal-container {
        .modal-header h2 {
            margin-top: 42px;
        }
    }

    .try-it-form {
        margin: 20px;
    }

    .modal-badge {
        position: absolute;
        top: 0;
        left: 0;
        padding: 8px 14px;
        background-color: $raspberry;
        color: white;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
        z-index: 5;
        border-top-left-radius: 10px;
    }
}
