
@import "../../../sass/imports";

.preferences-form {

    textarea, input[type="tel"], input[type="text"], input[type="number"], input[type="password"], input[type="email"] {
        background-color: transparent;
        border: solid 0.5px $bluey-gray;
        font-size: 16px;
        color: darken($raspberry, 10%);
        padding: 8px;
        @include border-radius(0);
        @include transition(all 0.25s ease-in-out);
        -webkit-appearance: none;
        min-width: 60px;

        &:focus {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
        }

        &:disabled {
            opacity: 0.5;
        }

        @include placeholder {
            font-style: italic;
        }
    }

    input[type="number"] {
        font-family: monospace;
        text-align: right;
        padding: 10px 8px;
        vertical-align: 7%; // Why?
        font-size: 16px;
    }

    .warning {
        color: white;
        padding: 15px 20px;
        background-color: $text-gray;
        @include border-radius(5px);
        margin: 10px;
        font-size: 14px;
        text-align: left;
        font-weight: normal;

        a {
            color: $mint-green3;
            font-weight: bold;
            text-decoration: underline;
        }

        em {
            font-weight: bold;
        }
    }

    .select-container {
        display: inline-block;
        border: solid 0.5px $bluey-gray;
        background-color: transparent;

        font-size: 16px;
        padding: 5px 8px;
        width: 100%;

        .placeholder {
            font-size: 10px;
            font-weight: bold;
            color: $bluey-gray;
            padding: 7px;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .value {
            font-size: 16px;
            color: darken($raspberry, 10%);
            font-weight: bold;
            padding: 4px 4px 3px 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            em {
                color: $text-gray;
            }
        }

        .select-dropdown p {
            text-transform: uppercase;
            width: 100%;
            font-size: 10px;
            color: $text-gray;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .section-heading {
        font-size: 16px;
        line-height: 1.2;
        display: block;
        font-weight: bold;
        padding: 5px;
        text-transform: uppercase;
        color: $text-navy;
    }

    .with-label {
        display: inline-block;
        text-align: left;
        margin: 5px;
        margin-bottom: 15px;
        vertical-align: top;

        label {
            display: block;
            color: $text-gray;
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 6px;

            em {
                opacity: 0.5;
            }
        }

        input, textarea {
            width: 100%;
        }
    }

    .desktop-only {
        @include min-screen(47em) {
            display: none !important;
        }
    }

    .mobile-only {
        @include max-screen(47em) {
            display: none !important;
        }
    }

    .with-units {
        display: inline-block;
        position: relative;
        margin: 0;
        width: 86%;
        input {
            width: calc(100%);
            margin-right: 5px;
        }

        label {
            position: absolute;
            top: 50%;
            left: 100%;
            width: initial;
            @include transform(translate(25%, -50%));
            display: inline-block;
            font-size: 10px;
            font-weight: bold;
            color: $text-navy;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }

    .description {
        margin: 5px;
        color: $text-gray;
        font-size: 14px;
    }

    .error-msg {
        display: block;
        font-weight: bold;
        color: red;
        padding: 10px;
        text-align: center;
        margin: 1em 20px;
        max-width: 450px;
        font-size: 14px;
        text-transform: initial;

        @include min-screen(480px) {
            margin: 1em auto;
        }
    }

    .select-bubbles {
        li {
            display: inline-block;
            padding: 10px;
            vertical-align: top;
            max-width: 112px;
            //padding-top: 0;

            &[data-disabled="true"] .dropdown-btn {
                opacity: 0.5;
            }
        }

        li > button, .dropdown-btn {
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.2);
            border: solid 1px $bluey-gray;
            width: 92px;
            height: 92px;
            font-size: 11px;
            font-weight: 900;
            text-transform: uppercase;
            padding: 0 4.5px;
            vertical-align: top;
            margin: 0 auto;
            cursor: pointer;
            color: $bluey-gray;
            position: relative;
            @include transition(all .25s ease-in-out);

            &[data-active="true"] {
                background-color: $raspberry;
                border: solid 1px $raspberry;
                color: white;
            }
        }
    }

    .alert-popover {
        p {
            text-align: left;
            padding-top: 0;
            min-width: 250px;
            color: $text-gray;
        }

        > i {
            color: $orange;
            font-size: 36px;
            float: left;
            margin-right: 15px;
            margin-bottom: 15px;
        }
    }

    .toggle-btn {
        border: 1px solid #7C8A99;
        background-color: transparent;
        color: #7C8A99;
        font-weight: bold;
        font-size: 12px;
        width: 100px;
        margin: 0 10px;
        padding: 10px;
        text-transform: uppercase;
        @include transition(all 0.25s ease-in-out);
        @include border-radius(5em);
        i {
            font-size: 15px;
            vertical-align: middle;
            display: inline-block;
            margin-right: .5em;
        }

        &[data-active="true"] {
            color: white;
            background-color: $raspberry;
            border: solid 1px $raspberry;
        }

        &:focus {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
        }
    }

    .checkbox {
        position: relative;
        display: block;
        margin: .5em 0;
        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            @include opacity(0);
        }
        span {
            display: inline-block;
            font-size: 14px;
            color: $text-gray;

            &:before {
                font-size: 12px;
                @include icon-font;
                color: white;
                display: inline-block;
                content: ' ';
                padding-top: .25em;
                padding-left: .25em;
                width: 20px;
                height: 20px;
                margin-right: .5em;
                @include transition(.66s color);
                vertical-align: middle;

                border: solid 0.5px $another-gray;
            }
        }
        input:checked + span {
            &:before {
                border: solid 0.5px #1d976b;
                background-color: $mint-green3;
                content: $icon-check;
            }
        }
    }

    .image-upload {
        position: relative;

        img {
            width: calc(100% - 20px);
            margin: 10px;
            @include box-shadow(0, 5px, 20px, 0, rgba(0, 0, 0, 0.1));
        }

        > footer {
            padding-top: 1px;
            text-align: center;
        }

        .icon-logo, .icon-female2, .icon-male2 {
            text-align: center;
            width: 200px;
            height: 200px;
            display: block;
            font-size: 85px;
            padding: 25px;
            color: $raspberry;
            margin: 10px auto;
            @include box-shadow(0, 5px, 20px, 0, rgba(0, 0, 0, 0.1));
        }

        .icon-female2, .icon-male2 {
            padding-top: 50px;
        }

        .icon-logo {
            height: 0;
            width: 200px;
            height: auto;
        }

        .delete-btn {
            position: absolute;
            top: 40px;
            right: 20px;

            border: none;
            background: $raspberry;
            color: rgba(255, 255, 255, 1);
            font-weight: bold;
            @include transition(all .2s ease-in-out);
            width: 26px;
            height: 26px;
            overflow: hidden;
            text-align: center;

            padding: 2px 5px;
            font-size: 16px;
            z-index: 5;

            &:hover {
                background: lighten($raspberry, 10%);
            }
        }
    }

    .sub-action-btn {
        @include outline-button($raspberry);
        @include border-radius(0);
        background-color: transparent;
        font-size: 12px;
        text-transform: uppercase;
        padding: 8px 10px;
        border-width: 0px;
        text-decoration: underline;
        text-align: center;
        margin: 5px;

        .icon-lock {
            display: inline-block;
            margin: 0 5px;
        }
    }

    [data-error="true"] {
        @include box-shadow(0, 2px, 6px, 0, rgba(255, 0, 0, 0.35));
        border: solid 0.5px $error-border !important;
    }


    .preferences-footer {
        padding: 15px;
        text-align: center;
    }

    .cancel-btn, .ok-btn {
        @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.1));
        @include border-radius(5em);
        background-color: white;
        border: solid 0.5px $inactive-text-gray;
        font-size: 14px;

        padding: 10px;
        min-width: 100px;
        width: calc(50% - 20px);
        max-width: 200px;
        margin: 10px;
        text-transform: uppercase;
        color: $icon-inactive;

        border: solid 0.5px $inactive-text-gray;

        @include transition(all 0.333s ease-in-out);

        &:hover {
            background-color: $inactive-text-gray;
            color: white;
        }

        &:focus {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.3));
        }

        &:disabled {
            opacity: 0.25;
        }
    }

    .ok-btn {
        border: solid 0.5px $orange3;
        background-color: $orange3;
        color: white;

        &:hover {
            background-color: darken($orange3, 20%);
            border: solid 0.5px darken($orange3, 20%);
            color: white;
        }
    }

    .preference-group {
        max-width: 400px;
        width: 100%;
        margin: 0 auto 35px auto;
        padding: 0 20px;

        .toggle-btn {
            margin: 5px;
            width: calc(50% - 10px);
        }
    }

    .preference-group-long {
        max-width: 400px;
        width: 100%;
        margin: 0 auto 40px auto;
        padding: 0 20px;

        .toggle-btn {
            margin: 10px auto;
            width: calc(100% - 10px);
            max-width: 400px;
            display: block;
        }
    }
}

.off-switch-dark {
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    margin: 9px 0;
    color: $text-gray;
    font-size: 14px;
    border: none;
    background: none;
    padding: 0 60px 0 0;
    text-transform: uppercase;

    > span {
        position: absolute;
        top: 50%;
        right: 0;
        @include border-radius(30px);
        @include transform(translate(0, -50%));
        display: block;
        min-width: 60px;
        font-size: 14px;
        text-transform: uppercase;
        border: 1px solid $text-gray;
        background-color: $text-gray;
        padding: 1px 0 2px 23px;
        color: white;

        &:after {
            @include icon-font;
            position: absolute;
            top: 50%;
            @include transform(translate(0, -50%));
            @include transition(left .1s ease-in-out);
            font-size: 22px;
            content: $icon-bullet;
            left: 0;
        }
    }

    &[data-active="true"] {
        > span {
            border: 1px solid $raspberry;
            background-color: $raspberry;
            padding: 1px 17px 2px 5px;
            color: white;

            &:after {
                left: calc(100% - 22px);
                color: white;
            }
        }
    }

    &:disabled {
        opacity: 0.5;
    }
}

.off-switch {
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    margin: 9px 0;
    color: $text-gray;
    font-size: 14px;
    border: none;
    background: none;
    padding: 0 60px 0 0;

    > span {
        position: absolute;
        top: 50%;
        right: 0;
        @include border-radius(30px);
        @include transform(translate(0, -50%));
        display: block;
        min-width: 45px;
        font-size: 9px;
        text-transform: uppercase;
        border: 1px solid $light-gray-border;
        padding: 2px 0 2px 19px;
        color: $bluey-gray;

        &:after {
            @include icon-font;
            position: absolute;
            top: 50%;
            @include transform(translate(0, -50%));
            @include transition(right .1s ease-in-out);
            font-size: 16px;
            content: $icon-bullet;
            right: 25px;
        }
    }

    &[data-active="true"] {
        > span {
            border: 1px solid $raspberry;
            padding: 2px 17px 2px 5px;
            color: white;
            background-color: $raspberry;

            &:after {
                right: 0;
                color: white;
            }
        }
    }
}
