
@import "../../../../../../sass/imports";

.add-conditions {
    > button {
        width: 280px !important;
        color: $raspberry;
        border: 1px solid $raspberry;
        margin: 10px;

        &:before {
            color: $raspberry;
        }
    }

    margin: 0 auto;
    cursor: pointer;

    p {
        text-align: center;
    }
}

.add-conditions-container {
    .editor-scrollable {
        top: 170px !important;
        bottom: 170px !important;
    }

    .other {
        position: absolute;
        bottom: 102px;
        left: 40px;
        right: 40px;
        border: 1px solid $inactive-text-gray;

        > input {
            width: 100%;
            font-size: 12px;
            color: $text-gray;
            padding: 17px;
            border: none;
        }
    }

}
