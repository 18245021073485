
@import "../../../../../../sass/imports";

.product-filters-modal {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    width: 100vw;
    height: 100vh;
    background: white;

    @include min-screen(47em) {
        top: 50%; bottom: auto;
        left: 50%; right: auto;
        width: auto; height: auto;
        @include transform(translate(-50%, -50%));
        @include border-radius(3px);
    }
}

.product-filters-container {
    padding: 120px 20px 80px 20px;
    max-width: 100vw;
    max-height: 100vh;
    overflow-y: auto;

    @include min-screen(47em) {
        width: 550px;
        height: 550px;
        max-height: calc(100vh - 40px);
        max-width: calc(100vw - 40px);
        padding: 20px 20px 80px 20px;
    }

    > header {
        width: 100%;

        @include max-screen(47em) {
            position: absolute;
            z-index: 3;
            top: 0;
            left: 0;
            text-align: center;
            padding: 20px;
            color: white;

            &:after {
                position: absolute;
            }
        }

        h1 {
            text-transform: uppercase;
            font-size: 16px;
            padding: 5px 0 0 0;

            @include min-screen(47em) {
                font-size: 20px;
                margin: 0 0 16px 0;
            }
        }

        .close-modal-btn {
            float: left;
            border: none;
            background: none;
            color: $bluey-gray;
            font-size: 22px;

            @include min-screen(47em) {
                display: none;
            }
        }
    }

    .top-half-circle {
        @include transform(translate(-50%,calc(-100% + 100px)));
        z-index: 1;

        @include min-screen(47em) {
            display: none;
        }
    }

    > footer {
        position: absolute;
        background-color: white;
        width: 100%;
        bottom: 0;
        left: 0;
        text-align: center;
        padding: 20px;
    }

    .panel {
        display: block;
        width: 100%;
    }

    .with-label {
        > label {
            font-size: 15px;
            font-weight: bold;
            color: $text-navy;
            text-transform: uppercase;
            letter-spacing: 1.2px;
        }
    }

    .toggle-group {
        margin: 5px 0;

        > li {
            width: calc(50% - 5px);
            display: inline-block;
            margin: 0 5px 5px 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .toggle-btn {
        border: none;
        background: none;
        position: relative;
        padding: 0 0 5px 0;
        text-align: left;
        width: calc(100% - 10px);
        color: $text-navy;

        &:before {
            content: ' ';
            display: inline-block;
            border: 1px solid #979797;
            width: 20px;
            height: 20px;
            @include border-radius(4px);
            vertical-align: middle;
            margin: 0 5px 0 0;
        }

        &[data-active="true"] {
            &:before {
                @include icon-font;
                content: $icon-check2;
                background-color: $mint-green3;
                font-size: 14px;
                color: $text-gray;
            }
        }
    }

    .nutrient-range-filters {
        text-align: left;
    }

    .nutrient-filter {
        padding: 0 .5em .5em 0 !important;
    }


    .ok-btn, .cancel-btn {
        color: white;
        padding: 13px 25px;
        @include border-radius(40px);
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(174, 174, 174, 0.2);
        font-size: 11px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1.27;
        letter-spacing: 1.1px;
        border: none;
    }

    .cancel-btn {
        background-color: $bluey-gray;
        margin: 0 56px 0 0;
    }

    .ok-btn {
        background-color: $raspberry;
    }


}
