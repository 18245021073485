
@import "../../../../sass/imports";

.patient-family-members {
    padding: 20px;

    color: $text-gray;

    h3 {
        font-size: 14px;
        color: #425569;;
        text-transform: uppercase;
    }

    table {
        margin: 1em 0;
        display: inline-block;
    }

    thead tr {
        background-color: $light-gray-border;
    }

    tbody tr {
        @include border-radius(5px);

        &:nth-child(even) {
            background-color: $light-gray-border;
        }
    }

    th {
        font-size: 14px;
        text-align: center;
        text-transform: uppercase;
        color: $text-gray;
        padding: 0 10px;
    }

    td {
        text-align: center;
        font-size: 12px;
        color: $text-gray;
    }

    .person-info {
        text-align: left;
        font-size: 14px;
        vertical-align: middle;
        padding: 5px 10px;

        em {
            font-weight: bold;
        }
    }

    button {
        color: $text-gray;
        margin: 10px;
        cursor: default;
        border: none;
        background: none;

        width: 30px;
        height: 30px;

        &:after {
            @include icon-font;
            content: $icon-close-x;
        }

        &[data-active="true"] {
            color: $mint-green3;

            &:after {
                content: $icon-check2;
            }
        }
    }
}
